.footer {
    color: #333;
    padding: 50px 0px 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: #f9f9f9;
}

.footer_element_container {
    display: flex;
    padding: 5px 0 0px;
    margin-top: 10px;
}

.footer_element_container > a {
    /* border: 1px solid #fff; */
    padding: 5px 3px;

    cursor: pointer;
    margin: 0 5px;
    font-size: 12px;
    color: #333;
    text-decoration: none;
    /* font-weight: bold; */
}

.logo {
    width: 100px;
}
