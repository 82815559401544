.header_container {
    /* border-bottom: 1px solid #e9e9e9; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 100%;
}

.header {
    width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header_menu {
    margin-right: 20px;
    font-size: 16px;
    /* font-weight: bold; */
    cursor: pointer;
    color: #333;
    text-decoration: none;
}

.header_sub_menu {
    font-size: 14px;
    margin-right: 10px;
    color: #222;
    text-decoration: none;
}

.header_left {
    display: flex;
    padding-left: 20px;
    align-items: center;
}

.header_right {
    display: flex;
    padding-right: 20px;
    align-items: center;
}

.menu {
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}

.menuItem {
    font-size: 25px;
    padding: 10px 0;
    line-height: 50px;
    cursor: pointer;
    margin-top: 0rem;
    /* border-bottom: 1px solid #fff; */
    color: #333;
    text-decoration: none;
}

.menu_button {
    display: none !important;
}

@media (max-width: 1000px) {
    .header_left,
    .header_right {
        display: none;
    }

    .menu_button {
        display: inline-block !important;
    }
}
