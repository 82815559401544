@import url(https://fonts.googleapis.com/css2?family=Noto+Serif+KR:wght@200;300;400;500;600;700;900&display=swap);
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

img {
    -webkit-user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

@font-face {
  font-family: "GmarketSansMedium";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

/* 순바탕체 추가 */
@font-face {
  font-family: "SunBatang-Light";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_eight@1.0/SunBatang-Light.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}
/* 순바탕체 추가 */

/* 리디바탕 */
@font-face {
  font-family: "RIDIBatang";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_twelve@1.0/RIDIBatang.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}
/* 리디바탕 */

/* 프리텐다드 */
@font-face {
  font-family: "Pretendard-Regular";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");
  font-weight: 400;
  font-style: normal;
}
/* 프리텐다드 */

textarea {
  border: none;
  overflow: auto;
  outline: none;
  box-shadow: none;

  resize: none; /*remove the resize handle on the bottom right*/
}

* {
  font-family: "Noto Sans KR", sans-serif;
}

p {
  padding: 0;
  margin: 0;
}

.MuiStepper-root {
  padding-top: 8px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.MuiStepLabel-label {
  font-size: 12px !important;
  word-break: keep-all !important;
}

.MuiStepIcon-completed {
  color: #f76c6c !important;
}

.MuiStepIcon-active {
  color: #f76c6c !important;
}

.MuiOutlinedInput-inputMultiline {
  font-size: 14px !important;
}

#custom_check {
  margin: 0;
  align-items: flex-start;
}

#custom_check .MuiFormControlLabel-label {
  font-size: 12px;
  color: #999;
  padding-top: 1px;
}

#custom_check span {
  padding-top: 0px;
}

#custom_check_2 {
  margin: 0;
  align-items: flex-start;
}

#custom_check_2 .MuiFormControlLabel-label {
  font-size: 12px;
  color: #333;
  padding-top: 1px;
}

#custom_check_2 span {
  padding-top: 0px;
}

.survey_button {
  display: flex;
  border: 1px solid #eaeaea;
  width: 32%;
  height: 40px;
  justify-content: center;
  align-items: center;
  color: #888888;
  cursor: pointer;
  border-radius: 5px;
}

.survey_search_content {
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 20px;
  padding-bottom: 20px;
  cursor: pointer;
}

.cryu-lazy-loading {
  opacity: 0;
}

.cryu-lazy-loaded {
  opacity: 1;
  transition: opacity 200ms;
}

/* 페이지 네이션 */
.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.pagination li {
  margin: 0 3px;
}

.pagination .selected a {
  color: #333;
  font-weight: bold;
}

.pagination a {
  display: flex;
  width: 25px;
  height: 25px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #999;
  font-size: 14px;
}

/* 페이지 네이션 */

.blurEffect {
  filter: blur(5px);
  -webkit-filter: blur(5px);
}

/* mockup */

@media (max-width: 1000px) {
  .prod-mockup-wrap {
    display: none;
  }

  .bt-prod-mockup-wrap {
    display: flex !important;
    justify-content: center;
  }

  .bt-prod-mockup-wrap .prod-mockup {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .prod-block-container {
    padding-bottom: 0px !important;
  }

  .form-prod-block-container {
    padding-bottom: 0px !important;
  }

  .mypage-box {
    width: calc(100% - 55px) !important;
  }
}

.prod-mockup-wrap {
  flex: 1 1;
  margin-right: 2rem;
}

.bt-prod-mockup-wrap {
  flex: 1 1;
  display: none;
  padding: 0 0 100px;
}

.prod-mockup {
  border: 10px solid #fff;
  width: 400px;
  height: 800px;
  border-radius: 50px;
  overflow: hidden;
  /* margin-top: 2rem; */
  float: right;
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
}

.prod-mockup > div {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.prod-mockup > div::-webkit-scrollbar {
  display: none;
}

.prod-mockup iframe::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  display: none;
}

.blinking > .save-button {
  display: flex !important;
  opacity: 1 !important;
}

.blinking {
  animation: blink 0.5s ease-in-out 1;
  border-color: #182e0c;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* mockup */

.mypage-box {
  display: inline-block;
  width: calc(50% - 55px);
  border: 1px solid #f2f4f6;
  border-radius: 10px;
  padding: 15px 15px;
  margin: 10px 10px;
  background-color: #fafafa;
  vertical-align: top;
}

/* ProductionScreen */
.prod-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  top: 0;
  position: -webkit-sticky;
  position: sticky;
}

.prod-item-container {
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  /* max-height: 100vh; */
  width: 100%;
  /* overflow-y: scroll; */
  flex: 1 1;
  overflow: hidden;
}

.form-prod-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  top: 0;
}

.form-prod-item-container {
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  /* max-height: 100vh; */
  width: 100%;
  /* overflow-y: scroll; */
  flex: 1 1;
  overflow: hidden;
}

.prod-address {
  height: 50px;
  border-radius: 25px;

  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1.5rem;
}

.prod-address-wrap {
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 85%;
  padding-right: 5px;
}

.prod-address-span-1 {
  font-size: 0.8rem;
}

.prod-address-span-2 {
  font-size: 0.8rem;
  font-weight: bold;
  margin-left: 3px;
  cursor: pointer;
  text-decoration: underline;
}

.prod-address-button {
  background-color: #333;
  color: #fff;
  border-radius: 2rem;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  margin-right: 0.5rem;
  width: 15%;
  min-width: 70px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 12px;
}

.prod-block-container {
  margin-top: 1rem;
  padding-bottom: 100px;
}

.prod-block-top {
  cursor: pointer;
  padding-bottom: 12px;
  border-bottom: 1px dashed rgb(233, 233, 233);
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 25px;
}

.prod-block-top-title {
  font-weight: bold;
  margin-left: 0px;
  margin-bottom: 0px;
  color: rgb(51, 51, 51);
  margin-left: 6px;
}

.prod-block-bottom {
  margin-top: 18px;
  margin-bottom: 18px;
  height: auto;
  display: block;
}

.form-prod-block-container {
  margin-top: 1rem;
  padding-bottom: 100px;
}

.form-prod-block-top {
  cursor: pointer;
  padding-bottom: 12px;
  border-bottom: 1px dashed rgb(233, 233, 233);
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 25px;
}

.form-prod-block-top-title {
  font-weight: bold;
  margin-left: 0px;
  margin-bottom: 0px;
  color: rgb(51, 51, 51);
  margin-left: 6px;
}

.form-prod-block-bottom {
  margin-top: 18px;
  margin-bottom: 18px;
  height: auto;
  display: block;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.prod-block {
  border: 1px solid #e9e9e9;
  background-color: #fefcfc;
  max-width: 500px;
  margin: 0.5rem 0;
  border-radius: 8px;
  padding: 12px 18px;
}

.form-prod-block {
  border: 1px solid #e9e9e9;
  background-color: #fefcfc;
  max-width: 500px;
  margin: 0.5rem 0;
  border-radius: 8px;
  padding: 12px 18px;
}

.prod-block:hover {
  border-color: #333;
}

.form-prod-block:hover {
  border-color: #333;
}

.prod-text-input:focus {
  outline: none !important;
}

.ql-container {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #fefcfc;
}

.ql-snow.ql-toolbar {
  display: block;
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

.ql-editor {
  min-height: 18em;
}

.themeSwitcher {
  margin-top: 0.5em;
  font-size: small;
}

.prod-button {
  background-color: #182e0c;
  padding: 10px 20px;
  display: inline-block;
  color: #fff;
  font-size: 1rem;
  border-radius: 8px;
  cursor: pointer;
}

.prod-select-container {
  width: 200px;
  height: 40px;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  background-color: #fefcfc;
  font-size: 0.9rem;
  overflow: hidden;
  padding: 0 8px;
}

.prod-select {
  width: 100%;
  height: 100%;
  background-color: #fefcfc;
  border: none;
  font-size: 0.9rem;
  color: #333;
  cursor: pointer;
}
.prod-select:focus {
  outline: none;
}

.prod-color-picker-wrap-click {
  border: 6px solid #f0f0f9 !important;
}

.prod-color-picker-wrap-click > div {
  border: 3px solid #483fce !important;
}

.prod-color-picker-wrap {
  margin: 5px;
  border-radius: 40px;
  border: 6px solid #fefcfc;
}

.prod-color-picker-border {
  padding: 2px;
  border-radius: 30px;
  border: 3px solid #fefcfc;
}

.prod-color-picker-content {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  cursor: pointer;
}

/* ProductionScreen */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.main-design-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  background-color: #f9f9f9;
  cursor: pointer;
  margin: 10px;
}

.main-design-wrap:hover {
  background-color: #f2f2f2;
}

.main-design-text {
  font-size: 12px;
  padding: 10px 0 5px;
}

.main-design-text b {
  /* background-color: #999;
    color: #fff;
    padding: 1px 5px 3px;
    border-radius: 20px;
    font-size: 10px;
    font-weight: 500; */
  font-size: 10px;
}

.SortIcon {
  font-size: 1.1rem !important;
}

/* 메인화면 */

.global-nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 44px;
  padding: 0 1rem;
}

.local-nav {
  position: absolute;
  top: 45px;
  left: 0;
  width: 100%;
  height: 52px;
  border-bottom: 1px solid #ddd;
  padding: 0 1rem;
}

.global-nav-links,
.local-nav-links {
  display: flex;
  align-items: center;
  max-width: 1000px;
  height: 100%;
  margin: 0 auto;
}

.global-nav-links {
  justify-content: space-between;
}

.local-nav-links .product-name {
  margin-right: auto;
  font-size: 1.2rem;
  font-weight: bold;
}

.local-nav-links a {
  font-size: 0.8rem;
}

.local-nav-links a:not(.product-name) {
  margin-left: 2em;
}

#scroll-section-0 h1 {
  font-size: 2.5rem;
  text-align: center;
  color: #fff;
  position: relative;
  top: calc(50vh - 116px);
}

.main-message {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3em;
  font-size: 1.8rem;
  margin: 5px 0;
  top: calc(50% - 86px);
  color: #fff;
}

.main-message p {
  line-height: 1.2;
  font-weight: bold;
  text-align: center;
}

.main-message small {
  display: block;
  margin-bottom: 0.5em;
  font-size: 1.2rem;
}

#scroll-section-2 .main-message {
  font-size: 3.5rem;
}

.description {
  font-size: 1.2rem;
  color: #888;
  padding: 0 1rem;
  max-width: 1000px;
  margin: 0 auto;
}

.description strong {
  font-size: 3rem;
  float: left;
  margin-right: 0.2em;
  color: rgb(29, 29, 31);
}

.desc-message {
  font-weight: bold;
  width: 50%;
}

.pin {
  width: 1px;
  height: 100px;
  background: rgb(29, 29, 31);
}

.mid-message {
  font-size: 2rem;
  color: #888;
  padding: 0 1rem;
  max-width: 1000px;
  margin: 0 auto;
  opacity: 0;
}

.mid-message strong {
  color: rgb(29, 29, 31);
}

.canvas-caption {
  color: #888;
  padding: 0 1rem;
  font-size: 1.2rem;
  max-width: 1000px;
  margin: 0 auto;
}

.sticky-elem {
  display: none;
  position: fixed;
  left: 0;
  width: 100%;
}

#show-scene-0 #scroll-section-0 .sticky-elem,
#show-scene-1 #scroll-section-1 .sticky-elem,
#show-scene-2 #scroll-section-2 .sticky-elem,
#show-scene-3 #scroll-section-3 .sticky-elem {
  display: block;
}

#scroll-section-1 {
  background-color: #fff;
  position: relative;
}

.main-content-container {
  padding: 100px 16px;
  max-width: 1120px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-content-wrap-1 {
  padding: 30px 16px;
}

.main-content-wrap-2 {
  width: 450px;
  height: auto;
}

.main-content-title {
  font-size: 44px;
  font-weight: bold;
}

.main-content-des {
  font-size: 20px;
  color: #999;
  margin-top: 10px;
}

.main-make-button {
  font-size: 16;
  background-color: #182e0c;
  width: 200px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 25px;
  cursor: pointer;
  margin-top: 50px;
  text-decoration: none;
}

.main-reverse {
  flex-direction: row-reverse;
}

.main-reverse .main-content-title,
.main-reverse .main-content-des {
  text-align: right;
}

.main-reverse .main-make-button {
  float: right;
}

.main-generate-container {
  height: 900px;
  width: 100%;

  position: relative;
}

.main-generate-wrap {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
}

.main-generate-wrap .main-make-button {
  margin: 0 auto;
  margin-top: 40px;
}

.main-generate-title {
  font-size: 40px;
  font-weight: bold;
}

.main-generate-des {
  font-size: 16px;
  margin-top: 10px;
}

#show-scene-1 .sec1-background-opacity {
  opacity: 1 !important;
  background-color: #fff !important;
}

#show-scene-0 .main-lottie-arrow {
  position: fixed;
  right: 0px;
  bottom: 20px;
}

#show-scene-1 .main-lottie-arrow {
  display: none;
}

@media (max-width: 1000px) {
  #scroll-section-0 h1 {
    font-size: 1.8rem !important;
    top: calc(50vh - 82px);
  }

  .main-content-container {
    flex-direction: column;
  }

  .main-content-title,
  .main-reverse .main-content-title {
    text-align: center;
    font-size: 26px;
  }

  .main-content-des,
  .main-reverse .main-content-des {
    text-align: center;
    font-size: 14px;
    margin-top: 8px;
  }

  .main-make-button,
  .main-reverse .main-make-button {
    float: none;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }

  .main-content-container {
    padding: 50px 16px 80px;
  }

  .main-content-wrap-2 {
    width: 100%;
    max-width: 450px;
  }

  .main-generate-container {
    height: 500px;
  }

  .main-generate-title {
    font-size: 26px;
  }

  .main-generate-des {
    font-size: 14px;
  }

  .prod-container > div {
    padding-top: 50px !important;
  }
}

/* 메인화면 */

/* 헤더 */
.new-header {
  width: 100%;
  height: 50px;
  top: 0px;
  z-index: 10000;
  position: fixed;
  transition: all 0.2s ease;
  /* background-color: #fff; */
  color: #333;

  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

.new-header-wrap {
  padding: 0 16px;
  max-width: 1000px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  line-height: 50px;
  height: 50px;
}

#show-scene-0 .new-header {
  background: transparent;
  color: #fff;

  backdrop-filter: none;
  -webkit-backdrop-filter: none;
}
.new-header-desktop {
  display: flex;
  align-items: center;
}

.new-header-element {
  margin: 0 13px;
  cursor: pointer;
  color: unset;
  text-decoration: none;
}

.new-header-making-button {
  background-color: #182e0c;
  width: 90px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: #fff;
  opacity: 0.9;
  cursor: pointer;
  text-decoration: none;
}

.new-header-mobile {
  display: none;
}

.new-header-mobile-menu {
  /* background-color: #fff; */
  color: #333;
}

.new-header-mobile-menu-link {
  padding: 0px 20px;
  font-size: 14px;
  height: 50px;
  line-height: 50px;
  cursor: pointer;
  display: block;
  text-decoration: none;
  color: #333;
}

.new-header-mobile-menu-link:hover {
  background-color: #f5f5f5;
}

@media (max-width: 1000px) {
  .new-header {
  }

  .new-header-desktop {
    display: none;
  }

  .new-header-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .new-header-making-button {
    font-size: 12px;
    width: 75px;
    height: 30px;
  }
}
/* 헤더 */

/* 자주 묻는 질문 */

.sub-page-container {
  margin-top: 200px;
}

.sub-page-title {
  font-size: 30px;
  line-height: 40px;
  text-align: center;
}

.sub-page-des {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin-top: 10px;
  color: #999;
}

.question-content-title {
  font-size: 18px;
}

.question-content-des {
  font-size: 14px;
}

.question-title {
  font-size: 25px;
  font-weight: bold;
}
.question-wrap {
  margin-top: 30px;
}

@media (max-width: 1000px) {
  .sub-page-title {
    font-size: 24px;
  }
  .sub-page-des {
    font-size: 14px;
    margin-top: 5px;
  }

  .sub-page-container {
    margin-top: 100px;
  }

  .question-content-title {
    font-size: 14px;
  }

  .question-content-des {
    font-size: 12px;
  }

  .question-title {
    font-size: 18px;
  }

  .question-wrap {
    margin-top: 10px;
  }
}

/* 자주 묻는 질문 */

/* 가격안내 */
.price-container {
  display: flex;
}

.price-wrap {
  flex: 1 1;
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 12px;
  border-radius: 15px;
  overflow: hidden;
  cursor: pointer;
}

.price-content-container {
  max-width: 1000px;
  margin: 100px auto 0 auto;
  padding: 0 16px;
}

@media (max-width: 1000px) {
  .price-container {
    flex-direction: column;
  }
  .price-wrap {
    margin-top: 30px;
  }
  .price-content-container {
    margin-top: 30px;
  }
}
/* 가격안내 */

.gallery-img-wrap {
  width: 200px;
  height: 200px;
  border: 1px solid #e9e9e9;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 5px;
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
}

@media (max-width: 500px) {
  .gallery-img-wrap {
    width: 140px;
    height: 140px;
  }
}

.Footer_footer__3Y8wl {
    color: #333;
    padding: 50px 0px 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: #f9f9f9;
}

.Footer_footer_element_container__2cMDT {
    display: flex;
    padding: 5px 0 0px;
    margin-top: 10px;
}

.Footer_footer_element_container__2cMDT > a {
    /* border: 1px solid #fff; */
    padding: 5px 3px;

    cursor: pointer;
    margin: 0 5px;
    font-size: 12px;
    color: #333;
    text-decoration: none;
    /* font-weight: bold; */
}

.Footer_logo__1C-FT {
    width: 100px;
}

.Header_header_container__3yNol {
    /* border-bottom: 1px solid #e9e9e9; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 100%;
}

.Header_header__1Eef- {
    width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Header_header_menu__1SBW8 {
    margin-right: 20px;
    font-size: 16px;
    /* font-weight: bold; */
    cursor: pointer;
    color: #333;
    text-decoration: none;
}

.Header_header_sub_menu__2sgFe {
    font-size: 14px;
    margin-right: 10px;
    color: #222;
    text-decoration: none;
}

.Header_header_left__2QJUg {
    display: flex;
    padding-left: 20px;
    align-items: center;
}

.Header_header_right__1pLWB {
    display: flex;
    padding-right: 20px;
    align-items: center;
}

.Header_menu__3YEFz {
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}

.Header_menuItem__w4lLW {
    font-size: 25px;
    padding: 10px 0;
    line-height: 50px;
    cursor: pointer;
    margin-top: 0rem;
    /* border-bottom: 1px solid #fff; */
    color: #333;
    text-decoration: none;
}

.Header_menu_button__Pd2me {
    display: none !important;
}

@media (max-width: 1000px) {
    .Header_header_left__2QJUg,
    .Header_header_right__1pLWB {
        display: none;
    }

    .Header_menu_button__Pd2me {
        display: inline-block !important;
    }
}

